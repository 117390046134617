import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  DataGridPro,
  gridClasses,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { Row, Col } from "react-bootstrap";

const ODD_OPACITY = 0.2;

const currencyFormatter = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

/* Format value grams */
const gramsFormatter = new Intl.NumberFormat("es-ES", {
  style: "decimal",
  minimumFractionDigits: 2,
});

const customFooter = (props) => {
  const { gridApi, page, pageCount, onPageChange } = props;

  return (
    <div>
      <Row className="border-top p-2 mx-2">
        {/* Total €*/}
        {/*         <Col className="justify-content-start align-items-center d-flex border-end">
          <Box>
            Total: {currencyFormatter.format(props.total)} ||{" "}
            {gramsFormatter.format(props.totalWeight)} g
          </Box>
        </Col> */}
        {/* Total Socios*/}
        {/*         <Col className="justify-content-start align-items-center d-flex border-end">
          <Box>Socios : {props.members}</Box>
        </Col> */}
        {/* Pagination */}
        <Col className="justify-content-center align-items-center d-flex">
          <Box>
            <Pagination
              count={pageCount} // Número total de páginas
              page={page} // Página actual
              onChange={(event, newPage) => {
                // Llamada para actualizar la página
                onPageChange(newPage); // Reducir 1 porque MUI Pagination es 1-indexed, pero el DataGrid es 0-indexed
              }}
            />
          </Box>
        </Col>
      </Row>
    </div>
  );
};

const StripedDataGridBase = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export function StripedDataGrid(props) {
  const [sortModel, setSortModel] = React.useState(props.sortModel);
  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <StripedDataGridBase
        className="shadow p-3 mb-3 bg-white rounded"
        rows={props.rows}
        columns={props.columns}
        sortModel={sortModel}
        onSortModelChange={(model) => {
          if (model.length > 0) {
            setSortModel(model);
          }
        }}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
    </div>
  );
}

export function StripedDataGridWithFooter(props) {
  const [total, setTotal] = React.useState(0);
  const [totalWeight, setTotalWeight] = React.useState(0);
  const [members, setMembers] = React.useState(0);

  // Sincronizamos el sortModel y la paginación con los props que se pasan
  const [sortModel, setSortModel] = React.useState(props.sortModel || []);
  const page = props.page; // Página actual
  const pageSize = props.pageSize; // Tamaño de la página actual
  const pageCount = Math.ceil(props.rowCount / pageSize); // rowCount dividido por pageSize

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <StripedDataGridBase
        className="shadow p-3 mb-3 bg-white rounded"
        rows={props.rows}
        columns={props.columns}
        page={page} // Página actual
        pageSize={pageSize} // Tamaño de la página actual
        rowCount={props.rowCount} // Total de filas
        paginationMode="server" // Paginación controlada por el servidor
        sortingMode="server" // Orden controlado por el servidor
        sortModel={sortModel} // Modelo de ordenación actual
        onSortModelChange={(newSortModel) => {
          setSortModel(newSortModel); // Actualiza el modelo de ordenación
          if (props.onSortModelChange) {
            props.onSortModelChange(newSortModel); // Llama a la función que maneja la ordenación en el padre
          }
        }}
        onPageChange={props.onPageChange} // Llama a la función que maneja el cambio de página
        onPageSizeChange={props.onPageSizeChange} // Llama a la función que maneja el cambio de tamaño de página
        components={{ Toolbar: GridToolbar, Footer: customFooter }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          footer: {
            total,
            members,
            totalWeight,
            page, // Página actual
            pageCount, // Número total de páginas
            onPageChange: props.onPageChange, // Función para cambiar de página
          },
        }}
        onStateChange={(state) => {
          /* Set VisibleRows */
          const visibleRows = state.filter.visibleRowsLookup;
          let visibleItems = [];
          for (const [id, value] of Object.entries(visibleRows)) {
            if (value === true) {
              visibleItems.push(id);
            }
          }
          const res = props.rows.filter((item) =>
            visibleItems.includes(item.id)
          );

          /* Set Total € */
          const total = res
            .map((item) => item.total)
            .reduce((a, b) => a + b, 0);
          setTotal(total);

          /* Set Total G */
          const totalWeight = res
            .map((item) => item.weight)
            .reduce((a, b) => a + b, 0);
          setTotalWeight(totalWeight);

          /* filter array by unique ids */
          const uniq = (arr, track = new Set()) =>
            arr.filter((memberObj) =>
              track.has(memberObj.member) ? false : track.add(memberObj.member)
            );
          const members = uniq(res);
          setMembers(members.length);
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
    </div>
  );
}
