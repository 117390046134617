import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  listTransactions,
  deleteTransactionAction,
} from "redux/actions/transactions";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGridWithFooter } from "components/custom/stripedDataGrid.js";

const currencyFormatter = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

const gramsFormatter = new Intl.NumberFormat("es-ES", {
  style: "decimal",
  minimumFractionDigits: 2,
});

export default () => {
  const dispatch = useDispatch();

  const transactionsReducer = useSelector((state) => state.transactions);
  const { loading, error, transactions, success } = transactionsReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  // Estado para manejar paginación y ordenación
  const [page, setPage] = useState(0); // Página actual
  const [pageSize, setPageSize] = useState(100); // Tamaño de la página
  const [sortModel, setSortModel] = useState([{ field: "date", sort: "desc" }]); // Orden
  const [totalRows, setTotalRows] = useState(0); // Total de filas

  const [rowsDefs, setRowsDefs] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteTransactionAction(id));
      window.location.href = "/transactions";
    }
  };

  const defineDataGrid = (transactions) => {
    let rows = [];

    transactions.transactions.forEach((transaction) => {
      const discount = (
        100 -
        (transaction.products
          .map(
            (product) =>
              product.quantity * product.stamps.price -
              product.quantity * product.stamps.price * (product.discount / 100)
          )
          .reduce((a, b) => a + b) /
          transaction.products
            .map((product) => product.quantity * product.stamps.price)
            .reduce((a, b) => a + b)) *
          100
      ).toFixed(2);

      console.log("Transaction w. example:", transaction);
      rows.push({
        id: transaction._id,
        member: transaction.memberId
          ? transaction.memberId.membershipId
          : "Unknown / Deleted Account",
        totalCost: transaction.totalCost,
        totalWeight: transaction.totalWeight,
        productsCount: transaction.productsCount,
        discount: discount > 0 ? discount + " %" : "0 %",
        signature: transaction.signature,
        date: transaction.date,
        user: transaction.user,
      });
    });

    setRowsDefs(rows);
    setColumnDefs([
      {
        headerName: "Fecha y hora",
        field: "date",
        width: 150,
        sortable: true,
        filter: true,
        type: "dateTime",
        renderCell: (params) => {
          return (
            <div>
              {new Date(params.value)
                .toISOString()
                .slice(0, 19)
                .replace(/-/g, "/")
                .replace("T", " ")}
            </div>
          );
        },
      },
      {
        headerName: "Id",
        field: "id",
        width: 70,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Socio",
        field: "member",
        width: 150,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Productos",
        field: "productsCount",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      {
        headerName: "Descuento",
        field: "discount",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      {
        headerName: "Total €",
        field: "totalCost",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: (params) => currencyFormatter.format(params.value),
      },
      {
        headerName: "Peso Total",
        field: "totalWeight",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: (params) => gramsFormatter.format(params.value) + " g",
      },
      {
        headerName: "Acciones",
        field: "actions",
        width: 200,
        renderCell: (rowData) => {
          return (
            <div>
              <Link to={`/transactions/${rowData.id}`}>
                <Button className="btn btn-sm m-2" variant="success">
                  Ver
                </Button>
              </Link>
              {userInfo?.permissions?.["members"]?.["delete"] && (
                <Button
                  variant="danger"
                  onClick={() => deleteHandler(rowData.id)}
                  className="btn btn-sm m-2"
                >
                  Eliminar
                </Button>
              )}
            </div>
          );
        },
      },
    ]);
  };

  useEffect(() => {
    console.log(sortModel);
  }, [sortModel]);

  useEffect(() => {
    dispatch(
      listTransactions(
        page + 1, // La página es 0-indexada en el frontend, por eso sumamos 1
        pageSize, // Tamaño de la página actual
        sortModel[0]?.field || "date", // Campo de ordenación (por defecto "date")
        sortModel[0]?.sort || "desc" // Orden de la ordenación (por defecto "desc")
      )
    );
  }, [dispatch, page, pageSize, sortModel, success, userInfo]);

  useEffect(() => {
    if (
      transactions &&
      transactions.transactions &&
      Array.isArray(transactions.transactions) &&
      transactions.transactions.length > 0
    ) {
      defineDataGrid(transactions);
      setTotalRows(transactions.total || 0); // Asegurarse de que 'total' tiene un valor válido
    }
    console.log(totalRows);
  }, [transactions]);

  return (
    <MainScreen
      title={`Transacciones`}
      button={
        userInfo?.permissions?.["transactions"]?.["create"] && (
          <Link to="/transactions/create">
            <Button className="m-2" variant="success">
              Nueva Transacción
            </Button>
          </Link>
        )
      }
    >
      <Row>
        {loading && <Loading />}
        {transactions && (
          <div>
            <StripedDataGridWithFooter
              rows={rowsDefs}
              columns={columnDefs}
              loading={loading}
              pagination
              page={page} // Página actual
              pageSize={pageSize} // Tamaño de la página actual
              rowCount={totalRows} // Total de filas (transacciones)
              paginationMode="server" // Paginación controlada por el servidor
              onPageChange={(newPage) => setPage(newPage)} // Cambiar página
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} // Cambiar tamaño de página
              sortingMode="server" // Orden controlado por el servidor
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            />
          </div>
        )}
      </Row>
      {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
    </MainScreen>
  );
};
