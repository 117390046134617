import { React, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InfoCard from "../../components/infoCard";
import TimeFilter from "../../components/timeFilter";
import TypeFilter from "../../components/typeFilter";
import LineChart from "../../components/lineChart";
import {
  faUser,
  faCannabis,
  faEuro,
  faWeightScale,
  faCashRegister,
  faDoorOpen,
} from "@fortawesome/free-solid-svg-icons";
import MainScreen from "../../components/layout/mainScreen/mainScreen";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/custom/loading";
import ErrorMessage from "../../components/custom/errorMessage";
import axios from "axios";

import { listDashboard } from "redux/actions/dashboard";
import { logout } from "redux/actions/users";

import { Paper } from "@mui/material";

const DashboardScreen = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const dashboardReducer = useSelector((state) => state.dashboard);
  const { loading, error, dashboard } = dashboardReducer;

  const [activeFilter, setActiveFilter] = useState("lastmonth");
  const [activeData, setActiveData] = useState([]);
  const [activeInfoFilter, setActiveInfoFilter] = useState("income");
  const [data, setData] = useState([]);

  const setActiveFilterData = (filter) => {
    setActiveFilter(filter);
    if (filter === "today") {
      setActiveData(dashboard.today);
    } else if (filter === "yesterday") {
      setActiveData(dashboard.yesterday);
    } else if (filter === "lastweek") {
      setActiveData(dashboard.lastWeek);
    } else if (filter === "lastmonth") {
      setActiveData(dashboard.lastMonth);
    }
  };

  const setActiveFilterType = (filter) => {
    setActiveInfoFilter(filter);
  };

  useEffect(() => {
    dispatch(listDashboard());
    if (!userInfo) window.location.href = "/login";
    if (error === "Not authorized, Token Failed") dispatch(logout());
  }, [userInfo]);

  useEffect(() => {
    if (dashboard) {
      setActiveData(dashboard.lastMonth);
    }
  }, [dashboard]);

  return (
    <MainScreen
      title="Dashboard"
      button={
        <Row className="justify-content-center d-flex my-3">
          <Col>
            <TimeFilter active={activeFilter} setFilter={setActiveFilterData} />
          </Col>
          <Col>
            {/* Type Filter */}
            <TypeFilter
              active={activeInfoFilter}
              setFilter={setActiveFilterType}
            />
          </Col>
        </Row>
      }
    >
      {loading && <Loading />}
      {dashboard && (
        <div className="shadow p-3 mb-3 bg-white rounded">
          <Row className="justify-content-center">
            <InfoCard
              icon={faUser}
              title="Socios"
              value={dashboard.membersCount}
              colSize="3"
              link="/members"
            />
            <InfoCard
              icon={faCannabis}
              title="Productos"
              value={dashboard.productsCount}
              colSize="3"
              link="/products"
            />
          </Row>

          <Row>
            {/* Make infoCard clickable */}

            <InfoCard
              icon={faEuro}
              title="Ingreso"
              value={
                activeData && activeData.total ? activeData.total.income : 0
              }
              colSize="3"
              sufix="€"
            />

            <InfoCard
              icon={faWeightScale}
              title="Ventas"
              value={
                activeData && activeData.total ? activeData.total.sells : 0
              }
              colSize="3"
              sufix="g"
            />
            <InfoCard
              icon={faCashRegister}
              title="Transacciones"
              value={
                activeData && activeData.total
                  ? activeData.total.transactionsCount
                  : 0
              }
              colSize="3"
              link="/transactions"
            />
            <InfoCard
              icon={faDoorOpen}
              title="Entradas"
              value={
                activeData && activeData.total
                  ? activeData.total.entriesCount
                  : "N/A"
              }
              colSize="3"
              link="/entries"
            />
          </Row>

          <Row className="justify-content-center">
            <LineChart
              data={activeData && activeData.data}
              infoFilter={activeInfoFilter}
            />
          </Row>
        </div>
      )}
      {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
    </MainScreen>
  );
};

export default DashboardScreen;
