import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Badge } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  listAllProducts,
  listProducts,
  deleteProductAction,
  activateProductAction,
} from "redux/actions/products";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGrid } from "components/custom/stripedDataGrid.js";

const currencyFormatter = new Intl.NumberFormat("es-ES", {
  style: "currency",
  currency: "EUR",
});

/* Format value grams */
const gramsFormatter = new Intl.NumberFormat("es-ES", {
  style: "decimal",
  minimumFractionDigits: 2,
});

export default () => {
  const dispatch = useDispatch();

  const productsReducer = useSelector((state) => state.products);
  const { loading, error, products, success } = productsReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  const [rowsDefs, setRowsDefs] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    dispatch(listAllProducts());
    if (!userInfo) window.location.href = "/login";
  }, [dispatch, success, userInfo]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteProductAction(id));
      window.location.href = "/products";
    }
  };

  const activateHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(activateProductAction(id));
      window.location.href = "/products";
    }
  };

  const defineDataGrid = (products) => {
    let rows = [];

    products.products.forEach((product) => {
      rows.push({
        id: product._id,
        category: product.category,
        ean: product.ean,
        name: product.name,
        description: product.description,
        price: product.price,
        buys: product.inventory.purchasedQuantity,
        sells: product.inventory.soldQuantity,
        inventory: product.inventory.inventory,
        weight: product.weight,
        deleted: product.deleted,
      });
    });

    setRowsDefs(rows);
    setColumnDefs([
      {
        headerName: "Interacciones",
        field: "id",
        width: 200,
        renderCell: (rowData) => (
          <div>
            {userInfo &&
              userInfo.permissions &&
              userInfo.permissions["purchases"]["create"] && (
                <Link to={`/purchases/create?productId=${rowData.row.id}`}>
                  <Button className="btn btn-sm m-2" variant="primary">
                    Comprar
                  </Button>
                </Link>
              )}
            {userInfo &&
              userInfo.permissions &&
              userInfo.permissions["transactions"]["create"] && (
                <Link to={`/transactions/create?productId=${rowData.row.id}`}>
                  <Button className="btn btn-sm m-2" variant="secondary">
                    Vender
                  </Button>
                </Link>
              )}
          </div>
        ),
      },
      {
        headerName: "Estado",
        field: "deleted",
        width: 100,
        renderCell: (rowData) => {
          return rowData.row.deleted ? (
            <Badge bg="danger">Eliminado</Badge>
          ) : (
            <Badge bg="success">Activo</Badge>
          );
        },
      },
      {
        headerName: "EAN",
        field: "ean",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Categoria",
        field: "category",
        width: 100,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Nombre",
        field: "name",
        width: 200,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Descripción",
        field: "description",
        width: 200,
        sortable: true,
        filter: true,
      },
      {
        headerName: "Precio",
        field: "price",
        width: 120,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
      },
      {
        headerName: "Peso",
        field: "weight",
        width: 120,
        sortable: true,
        filter: true,
        type: "number",
        valueFormatter: ({ value }) => {
          return gramsFormatter.format(value) + " g";
        },
      },
      {
        headerName: "Compras",
        field: "buys",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      {
        headerName: "Vendas",
        field: "sells",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      {
        headerName: "Éxistencias",
        field: "inventory",
        width: 100,
        sortable: true,
        filter: true,
        type: "number",
      },
      {
        headerName: "Acciones",
        field: "actions",
        width: 250,
        renderCell: (rowData) => {
          return (
            <div>
              <Link to={`/products/${rowData.id}`}>
                <Button className="btn btn-sm m-2" variant="success">
                  Ver
                </Button>
              </Link>{" "}
              {userInfo &&
                userInfo.permissions &&
                userInfo.permissions["members"]["update"] && (
                  <Link to={`/products/edit/${rowData.id}`}>
                    <Button className="btn btn-sm m-2" variant="warning">
                      Editar
                    </Button>
                  </Link>
                )}
              {userInfo &&
              userInfo.permissions &&
              userInfo.permissions["members"]["delete"] &&
              !rowData.row.deleted ? (
                <Button
                  variant="danger"
                  onClick={() => deleteHandler(rowData.id)}
                  className=" btn btn-sm m-2"
                >
                  Eliminar
                </Button>
              ) : (
                <Button
                  variant="success"
                  onClick={() => activateHandler(rowData.id)}
                  className=" btn btn-sm m-2"
                >
                  Activar
                </Button>
              )}
            </div>
          );
        },
      },
    ]);
  };

  setTimeout(() => {
    if (
      !rowsDefs.length &&
      products &&
      products.products &&
      products.products.length > 0
    ) {
      console.log(products);
      defineDataGrid(products);
    }
  }, 100);

  const customButton = () => {
    if (organisationInfo) {
      return (
        <Link to="/products/create">
          <Button className="m-2" variant="success">
            Nuevo Producto
          </Button>
        </Link>
      );
    } else {
      return (
        <Link to="/organisation/create">
          <Button className="btn btn-sm m-2" variant="success">
            Nueva Organización
          </Button>
        </Link>
      );
    }
  };

  return (
    <MainScreen
      title={`Productos`}
      button={
        userInfo &&
        userInfo.permissions &&
        userInfo.permissions["members"]["create"] &&
        customButton()
      }
    >
      <Row>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {loading && <Loading />}
        {products && products.products && (
          <div>
            <StripedDataGrid
              rows={rowsDefs}
              columns={columnDefs}
              loading={loading}
              sortModel={[{ field: "name", sort: "desc" }]}
            />
          </div>
        )}
      </Row>
    </MainScreen>
  );
};
