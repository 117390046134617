import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAILURE,
  PRODUCT_ALL_LIST_REQUEST,
  PRODUCT_ALL_LIST_SUCCESS,
  PRODUCT_ALL_LIST_FAILURE,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAILURE,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILURE,
  PRODUCT_ACTIVATE_REQUEST,
  PRODUCT_ACTIVATE_SUCCESS,
  PRODUCT_ACTIVATE_FAILURE,
} from "../constants/products";

import axios from "axios";
import apiConfig from "../../utils/apiConfig";

export const listProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });

    const { data } = await axios.get("/api/products", apiConfig);

    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PRODUCT_LIST_FAILURE,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const listAllProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_ALL_LIST_REQUEST });

    const { data } = await axios.get("/api/products/all", apiConfig);

    dispatch({ type: PRODUCT_ALL_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PRODUCT_ALL_LIST_FAILURE,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getProductById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST });

    const { data } = await axios.get(`/api/products/${id}`, apiConfig);

    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PRODUCT_LIST_FAILURE,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const createProductAction =
  (ean, category, name, description, price, weight, suppliersArray, image) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_CREATE_REQUEST });

      const { data } = await axios.post(
        "/api/products/create",
        {
          ean,
          category,
          name,
          description,
          price,
          weight,
          suppliersArray,
          image,
        },
        apiConfig
      );

      dispatch({ type: PRODUCT_CREATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: PRODUCT_CREATE_FAILURE,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const updateProductAction =
  (
    id,
    ean,
    category,
    name,
    description,
    price,
    weight,
    suppliersArray,
    image
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_UPDATE_REQUEST });

      const { data } = await axios.put(
        `/api/products/${id}`,
        {
          ean,
          category,
          name,
          description,
          price,
          weight,
          suppliersArray,
          image,
        },
        apiConfig
      );

      dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: PRODUCT_UPDATE_FAILURE,
        payload:
          err.response && err.response.data ? err.response.data : err.message,
      });
    }
  };

export const deleteProductAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_DELETE_REQUEST });

    const { data } = await axios.delete(`/api/products/${id}`, apiConfig);

    dispatch({ type: PRODUCT_DELETE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PRODUCT_DELETE_FAILURE,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const activateProductAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_ACTIVATE_REQUEST });

    const { data } = await axios.get(`/api/products/activate/${id}`, apiConfig);

    dispatch({ type: PRODUCT_ACTIVATE_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: PRODUCT_ACTIVATE_FAILURE,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
