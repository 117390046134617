import { useEffect, useState } from "react";
import MainScreen from "components/layout/mainScreen/mainScreen.js";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Badge, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listMembers, deleteMemberAction } from "redux/actions/members";
import { getTransactionsByDate } from "redux/actions/transactions";
import Loading from "components/custom/loading.js";
import ErrorMessage from "components/custom/errorMessage.js";
import { StripedDataGrid } from "components/custom/stripedDataGrid.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { TextField, Stack, Slider, Box } from "@mui/material";
import Switch from "./gr_eur_switch.js";

///import styles
import "./styles.css";

const getGradientColor = (min, max, value) => {
  //Create a function that takes a percentage and returns a color between red and green, where green is 100% and red is 0% with 50% opacity
  var percent = ((value - min) / (max - min)) * 100; /* -150 / 550 * 100 */
  var r,
    g,
    b = 0;
  if (percent < 0) {
    r = 255;
    g = 255;
    b = 255;
  } else if (percent < 50) {
    r = 255;
    g = Math.round(5.1 * percent);
  } else if (percent > 100) {
    r = 0;
    g = 255;
  } else {
    g = 255;
    r = Math.round(510 - 5.1 * percent);
  }
  var h = r * 0x10000 + g * 0x100 + b * 0x1;
  var hex = "#" + ("000000" + h.toString(16)).slice(-6);
  //set opacity to hex
  return hex + "60";
};

const customButton = (organisationInfo) => {
  if (organisationInfo) {
    return (
      <>
        <Link to="/members">
          <Button className="m-2" variant="primary">
            Socios
          </Button>
        </Link>
        <Link to="/members/create">
          <Button className="m-2" variant="success">
            Nuevo Socio
          </Button>
        </Link>
      </>
    );
  } else {
    return (
      <Link to="/organisation/create">
        <Button className="btn btn-sm m-2" variant="success">
          Nueva Organización
        </Button>
      </Link>
    );
  }
};

export default () => {
  const dispatch = useDispatch();

  const membersReducer = useSelector((state) => state.members);
  const { loading, error, members, success } = membersReducer;

  const transactionsReducer = useSelector((state) => state.transactions);
  const {
    loading: loadingTransactions,
    error: errorTransactions,
    transactions,
  } = transactionsReducer;

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const organisation = useSelector((state) => state.organisation);
  const { organisationInfo } = organisation;

  const [rowsDefs, setRowsDefs] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [date, setDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate())
  );
  const [gtDate, setGtDate] = useState(new Date());
  const [ltDate, setLtDate] = useState(new Date());
  const [grTreshold, setGrTreshold] = useState([0, 100]);
  const [eurTreshold, setEurTreshold] = useState([0, 1000]);
  const [transactionsbyMember, setTransactionsByMember] = useState(false);
  const [consumptionView_isGr, setConsumptionView_isGr] = useState(true);

  const handleSliderChanger = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (consumptionView_isGr) {
      const minDistance = 1;
      if (activeThumb === 0)
        setGrTreshold([Math.min(newValue[0], grTreshold[1] - minDistance), grTreshold[1]]);
      else setGrTreshold([grTreshold[0], Math.max(newValue[1], grTreshold[0] + minDistance)]);
    } else {
      const minDistance = 10;
      if (activeThumb === 0)
        setEurTreshold([Math.min(newValue[0], eurTreshold[1] - minDistance), eurTreshold[1]]);
      else setEurTreshold([eurTreshold[0], Math.max(newValue[1], eurTreshold[0] + minDistance)]);
    }
  };

  const handleSwitchChange = (event) => {
    console.log(event.target.checked);
    setConsumptionView_isGr(event.target.checked);
  };

  const defineDataGrid = async () => {
    console.log(consumptionView_isGr);
    if (ltDate) {
      const rows = [];
      const days = [];

      for (let i = 1; i <= ltDate.getDate(); i++) {
        days.push(i);
      }

      await members.forEach((member) => {
        const totalEur = transactionsbyMember[member._id]?.reduce((acc, transaction) => {
          return acc + transaction.totalEur;
        }, 0);
        const totalGr = transactionsbyMember[member._id]?.reduce((acc, transaction) => {
          return acc + transaction.totalGr;
        }, 0);
        rows.push({
          id: member._id,
          membershipId: member.membershipId,
          nickname: member.nickname,
          total_gr: totalGr ? totalGr : 0,
          total_eur: totalEur ? totalEur : 0,
          ...days.reduce((acc, day) => {
            const date = new Date(ltDate.getFullYear(), ltDate.getMonth(), day);
            const memberTxs = transactionsbyMember[member._id];

            const transaction = memberTxs?.find((transaction) => {
              //Transform transaction.date to be with time 0
              const txDate = new Date(
                new Date(transaction.date).getFullYear(),
                new Date(transaction.date).getMonth(),
                new Date(transaction.date).getDate(),
                0,
                0,
                0,
                0
              );
              return txDate.getTime() === date.getTime();
            });
            if (transaction) {
              acc[day] = {
                gr: transaction.totalGr,
                eur: transaction.totalEur,
              };
            } else {
              acc[day] = {
                gr: 0,
                eur: 0,
              };
            }
            return acc;
          }, {}),
        });
      });

      setRowsDefs(rows);
      setColumnDefs([
        {
          headerName: "ID Socio",
          field: "membershipId",
          width: 100,
          sortable: true,
          filter: true,
        },
        { headerName: "Nickname", field: "nickname", width: 100, sortable: true, filter: true },
        {
          headerName: "Total €",
          field: "total_eur",
          width: 100,
          sortable: true,
          hide: consumptionView_isGr,
          filter: true,
          renderCell: (params, row) => {
            {
              console.log(row);
            }
            return <div id="totalConsumption">{params.value?.toFixed(2)} €</div>;
          },
        },
        {
          headerName: "Total Gr",
          field: "total_gr",
          width: 100,
          sortable: true,
          hide: !consumptionView_isGr,
          filter: true,
          renderCell: (params, row) => {
            return <div id="totalConsumption">{params.value?.toFixed(2)} Gr</div>;
          },
        },
        ...days.map((day) => {
          return {
            headerName: day,
            field: day,
            width: 60,
            sortable: true,
            filter: true,
            renderCell: (params, row) => {
              const { eur, gr } = params.value;
              const valueToUse = consumptionView_isGr ? gr : eur;
              const treshold = consumptionView_isGr ? grTreshold : eurTreshold;
              const backgroundColor = getGradientColor(treshold[0], treshold[1], valueToUse);
              return (
                <div
                  id="totalConsumption"
                  style={{
                    backgroundColor: backgroundColor,
                  }}
                >
                  {valueToUse} {consumptionView_isGr ? "g" : "€"}
                </div>
              );
            },
          };
        }),
      ]);
    }
  };

  useEffect(() => {
    if (date) {
      setGtDate(new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0));
      setLtDate(new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999));
    }
  }, [date]);

  useEffect(() => {
    if ((gtDate, ltDate)) {
      console.log("gtDate", gtDate);
      console.log("ltDate", ltDate);
      dispatch(getTransactionsByDate(gtDate, ltDate));
    }
  }, [gtDate, ltDate]);

  useEffect(() => {
    if (transactions) {
      const transactionsByMember = transactions?.transactions?.reduce((acc, transaction) => {
        const { memberId, products } = transaction;
        let totalEur = products.reduce((acc, product) => {
          return acc + product.stamps.price * product.quantity;
        }, 0);
        let totalGr = products.reduce((acc, product) => {
          return acc + product.stamps.weight * product.quantity;
        }, 0);

        if (!acc[memberId]) {
          acc[memberId] = [];
        }
        acc[memberId].push({
          date: transaction.date,
          totalEur: totalEur,
          totalGr: totalGr,
        });
        return acc;
      }, {});
      setTransactionsByMember(transactionsByMember);

      setEurTreshold([
        0,
        transactions?.transactions?.reduce((acc, transaction) => {
          const { memberId, products } = transaction;
          let totalEur =
            products?.reduce((acc, product) => {
              return acc + product.stamps.price * product.quantity;
            }, 0) || 0;

          if (totalEur > acc) {
            acc = totalEur;
          }
          return acc;
        }, 0),
      ]);

      setGrTreshold([
        0,
        transactions?.transactions?.reduce((acc, transaction) => {
          const { memberId, products } = transaction;
          let totalGr =
            products?.reduce((acc, product) => {
              return acc + product.stamps.weight * product.quantity;
            }, 0) || 0;

          if (totalGr > acc) {
            acc = totalGr;
          }
          return acc;
        }, 0),
      ]);
    }
  }, [transactions]);

  useEffect(() => {
    dispatch(listMembers());
    if (!userInfo) window.location.href = "/login";
  }, [dispatch, success, userInfo]);

  useEffect(() => {
    if (members && transactionsbyMember) {
      defineDataGrid();
    }
  }, [members, transactionsbyMember, grTreshold, eurTreshold, consumptionView_isGr]);

  return (
    <MainScreen
      title={`Socios`}
      button={
        userInfo &&
        userInfo.permissions &&
        userInfo.permissions["members"]["create"] &&
        customButton(organisationInfo)
      }
    >
      <Form inline onSubmit={console.log("HI")}>
        <Row className="d-flex my-3">
          <Col>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year", "month"]}
                label="Year and Month"
                minDate={new Date("2012-03-01")}
                maxDate={new Date()}
                value={date}
                onChange={setDate}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </Col>
          <Col>
            {/* Slider from min to max placeholder */}
            <Box sx={{ width: 300 }}>
              <Slider
                value={consumptionView_isGr ? grTreshold : eurTreshold}
                onChange={handleSliderChanger}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={consumptionView_isGr ? 100 : 1000}
                step={consumptionView_isGr ? 1 : 10}
                getAriaLabel={() => "Temperature range"}
              />
            </Box>
          </Col>
          <Col>
            <Box>
              <Switch checked={consumptionView_isGr} onChange={handleSwitchChange} />
            </Box>
          </Col>
        </Row>
      </Form>
      <Row>
        {error && <ErrorMessage variant="danger">{error}</ErrorMessage>}
        {loading && <Loading />}
        {members && (
          <div id="consumptionsTable">
            <StripedDataGrid
              rows={rowsDefs}
              columns={columnDefs}
              loading={loading}
              sortModel={[{ field: "total", sort: "desc" }]}
            />
          </div>
        )}
      </Row>
    </MainScreen>
  );
};
