import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAILURE,
  PRODUCT_ALL_LIST_REQUEST,
  PRODUCT_ALL_LIST_SUCCESS,
  PRODUCT_ALL_LIST_FAILURE,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAILURE,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILURE,
  PRODUCT_ACTIVATE_REQUEST,
  PRODUCT_ACTIVATE_SUCCESS,
  PRODUCT_ACTIVATE_FAILURE,
  RESET_PRODUCT,
} from "../constants/products";

export const productsReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case PRODUCT_LIST_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_ALL_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_ALL_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case PRODUCT_ALL_LIST_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_CREATE_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_UPDATE_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_DELETE_FAILURE:
      return { loading: false, error: action.payload };
    case PRODUCT_ACTIVATE_REQUEST:
      return { loading: true };
    case PRODUCT_ACTIVATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_ACTIVATE_FAILURE:
      return { loading: false, error: action.payload };
    case RESET_PRODUCT:
      return {
        products: [],
      };
    default:
      return state;
  }
};
